.navbar-custom {
    background-color: var(--primary-cat-color);
}

footer {
    font-family: oxygen;
    padding-bottom: 1em;
    position: relative;
    z-index: 10;
}

footer .container-lg {
    padding: 30px;
}

footer > div[class] {
    padding-top: 30px;
}

.text-block {
    padding: 0px 25px 15px 25px;
}

.text-block p span {
    font-weight: 300;
}

footer a {
    color: var(--secondary-cat-color);
    font-family: oxygen;
}

footer a:focus,
footer a:hover {
    color: var(--secondary-cat-color);
}

p {
    margin: 2px;
}

p i {
    margin: 0px 20px 0 0;
    font-size: 6vw;
}

a {
    font-weight: normal;
}

h6 {
    margin-top: 1.5em;
}

.footer__legal-links {
    margin: 0;
    padding: 0;
}

.footer__legal-link {
    display: inline-block;
}

.footer__legal-link--dealer > a {
    text-decoration: underline;
}

* + .footer__legal-link::before {
    content: "|";
    margin: 0 0.25em;
}

@media (min-width: 768px) {
    .text-block {
        padding: 15px 0px 30px 140px;
    }

    footer p i {
        font-size: 18pt;
    }

    .footer__legal-links, footer a, footer p, footer h6 {
        font-size: 11pt;
    }
}

@media screen and (min-width:992px) {
    footer > .container-lg {
        padding: 0;
    }
}
