
body {
    background-image: none;
}

#home_page {
    margin-top: -0.2em;
}

#home_page .fa-eye,
#home_page .fa-eye-slash {
    color: var(--primary-cat-color);
}

#welcome_message {
    background-color: var(--secondary-cat-color);
    color: white;
    font-weight: normal;
    margin-bottom: 0;
    padding-top: 12px;
}

#welcome_message .container {
    padding-top: 12px;
    padding-bottom: 12px;
}

#welcome_message p {
    font-size: 16px;
    margin: 0 30px 0 0;
}

@media only screen and (min-width: 576px) {
    #welcome_message p {
        display: inline;
    }
}

#home_header {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.home_header__title {
    display: inline-block;
    font-size: 45px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
}

#add_application_button {
    position: relative;
    background-color: var(--primary-cat-color);
    border-radius: 0;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    margin: 7px 0;
    padding: 0 18px;
}


#add_application_button > * {
    margin-right: 10px;
}

#add_application_button > .number-icon {
    position: absolute;
    right: -33px;
    top: -20px;
}

#add_application_button .number-icon span {
    font-size: 16px;
    color: var(--primary-cat-color);
}

#add_application_button .number-icon i {
    font-size: 24px;
}

#help_me_now.help_me_now_home{
    left: 30px;
}

#help_me_now i.fa-comment {
    font-size: 33px;
}

#help_me_now i.fa-question {
    color: var(--primary-cat-color);
}

#help_me_text {
    color: var(--secondary-cat-color);
    font-size: 20px;
    padding: 0;
    margin-left: 15px;
    text-transform: uppercase;
}

#help_me_text:hover {
    text-decoration: none;
}

.home-page__subtitle {
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 28px;
}

.home-page__subtitle i {
    color: var(--primary-cat-color);
    margin-right: 10px;
    font-size: 1.125rem;
}

.home-page__tile-config-action {
    font-size: 1rem;
    text-align: right;
}

#applications {
    justify-content: space-between;
}

@media only screen and (max-width: 765px) { /* 765 is boundary to show two applications at once. */
    #applications {
        justify-content: space-around;
    }
}

@media only screen and (min-width: 765px) { /* 765 is boundary to show two applications at once. */
    #home_header {
        margin-top: 83px;
    }
    #help_me_now.help_me_now_home{
        left: auto;
        right: 30px;
    }
}

.application-block {
    display: flex;
    flex: 1;
    flex-direction: column;
    box-shadow: 0 3px 6px #00000029;
    background-color: white;
    padding: 0;
    margin: 0 15px 45px 15px;
    position: relative;
    min-width: 80%;
}
/* The following is a dummy placeholder block, to make the last line of items look better */
/* Only works up to 3 columns */
#applications::after {
    flex: 1;
    content: "";
    min-width: 80%;
    margin: 0 15px;
    height: 0;
}

@media only screen and (min-width: 765px) {
    .application-block, #applications::after {
        min-width: 40%;
    }
}

@media only screen and (min-width: 1200px) {
    .application-block, #applications::after {
        min-width: 25%;
    }
}

.application-block > * {
    margin: 0;
    padding: 0;
    flex-grow: 0;
}

.application__media {
    width: 100%;
    height: 9em;
    background-position: 50% 50%;
    background-size: cover;
    background-color: gray;
    border-top: 3px solid var(--primary-cat-color);
}

.application-block h3 {
    margin: 16px 16px 0;
    font-weight: bold;
}

.application-block ul {
    margin-top: 22px;
    flex-grow: 2;
}

.application-block li {
    padding-left: 8px;
    padding-right: 12px;
    font-weight: normal;
    display: flex;
    margin-bottom: 5px;
}

.application-block li div:last-child {
    line-height: 1.9;
}

.application-block .application-check-badge {
    flex-shrink: 0;
}

.application-block .application-check-badge i.fa-circle {
    font-size: 15px;
    color: var(--primary-cat-color);
}

.application-block .application-check-badge i.fa-check {
    font-size: 8px;
    color: var(--secondary-cat-color);
}

.application-block a,
.application-block button {
    width: 100%;
    margin: 16px;
    padding: 4px 16px;
    background-color: var(--primary-cat-color);
    border-radius: 0;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.application-block a i,
.application-block button i {
    font-size: 1.125rem;
}

.application-block.new-application a {
    background-color: #c1c1c1;
    color: white;
}

.application-block:hover a {
    color: white;
}

.application-block button {
    background-color: var(--secondary-cat-color);
    color: white;
}

.application-block .non-opaque.disabled,
.application-block button:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.75);
}

.application-block .application-remove-button {
    color: white;
    text-shadow: 0 0 0.2em rgba(0,0,0,0.5);
    position: absolute;
    top: 24px;
    right: 28px;
    font-size: 1.35rem;
    opacity: 0;
    transition: opacity 0.15s;
    z-index: 500;
    margin: -15px;
    padding: 15px;
}

.application-block:hover .application-remove-button {
    opacity: 1;
}


.application-block .link-wrapper {
    margin: 0;
    padding: 0;
}

.application-blok--invisible * {
    opacity: 0.75;
}

.application-blok--invisible .non-opaque {
    opacity: 1;
}

.new-application-badge {
    position: absolute !important;
    top: 3px;
    left: 40px;
    color: white;
    width: 52px;
    font-size: 20px;
}

.new-application-badge i {
    font-size: 52px;
    color: var(--primary-cat-color);
}

.first-login-modal .btn {
    background-color: var(--secondary-cat-color);
    color: white;
}