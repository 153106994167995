.header-myp {
    background-color: var(--primary-cat-color);
    z-index: 15;
}

.header-myp::before, .header-myp::after {
    content: "";
    background-color: rgba(253,186,18,.6);
    position: absolute;
    left: 0;
    right: 0;
    bottom: -8px;
    height: 8px;
    z-index: 3;
}

.header-myp::after {
    background-color: rgba(253,186,18,.2);
    bottom: -16px;
}

.header-myp .navbar-brand {
    padding: 0;
}

.fas.fa-chevron-right {
    margin-left: 10px;
    font-size: 0.725rem;
    top: -1px;
    position: relative;
}

.account-logo,
.flags {
    margin-right: 5px;
    cursor: pointer;
    width: auto;
    height: 20px;
}

header a {
    color: var(--secondary-cat-color);
    font-size: 20px;
}

header a:focus,
header a:hover {
    color: var(--secondary-cat-color);
}

.header {
    justify-content: space-between;
}

.header > div {
    padding: 10px;
    margin: 0;
}

#header_buttons div {
    margin-right: 1rem;
}

#header_buttons div:last-of-type {
    margin-right: 0px
}

.greeting {
    font-family: univers;
    font-size: 1.2rem;
}

#header_buttons .btn {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    color: #606060;
}

#header_buttons .btn:focus,
#header_buttons .btn:hover {
    text-decoration: underline;
}

.navbar-brand .logo {
    height: 30px;
}

@media screen and (max-width:768px) {
    #header_buttons {
        margin: 0;
        padding: 0;
    }

    #header_buttons .btn {
        padding: 9px 10px;
        color: var(--secondary-cat-color);
    }

    #header_buttons .btn:focus,
    #header_buttons .btn:hover {
        color: #606060;
    }

    #logout_button.btn {
        margin-top: 8px;
        margin-right: 0;
        position: absolute;
        top: 100%;
        right: 0;
        background: #F2F2F2;
        border-radius: 0;
        display: inline-block;
        z-index: 2;
        transition: margin-right 300ms;
    }

    .sidebar--visible #logout_button.btn {
        margin-right: -100%;
    }

    .navbar-brand .logo {
        height: 25px;
    }
}

@media only screen and (max-width: 576px) {
    #logout_button.btn {
        margin-top: calc(8px + 1.25em);
    }
}
