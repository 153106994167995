@import '~react-filterable-table/dist/style.css';

table {
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}

table thead {
	background:#000;
}

table thead tr th {
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

table tbody tr:nth-of-type(even) {
  background: #EEE;
}

table tbody tr td {
  padding: 10px;
}

table tbody tr td:first-of-type {
  font-weight: bold;
}

table tbody tr td a {
  font-family: inherit;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  width: 30px;
  height: 17px;
  top: 4px;
  margin-right: 0;
  float: right;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--primary-cat-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-cat-color);
}

input:checked + .slider:before {
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}




.filterable-table-container {
  width: 100%;
}

.filterable-table-container .header-row {
  margin-top: 6px;
}
.filterable-table-container .header-row .exact-filters {
  margin-top: 20px;
}
.filterable-table-container .header-row .record-count {
  text-align: right !important;
}
.filterable-table-container table.filterable-table thead tr th {
  white-space: nowrap;
  user-select: none;
}
.filterable-table-container table.filterable-table thead tr th span.fa {
  padding-left: 8px;
  color: #aaa;
}
.filterable-table-container table.filterable-table tfoot tr td {
  font-weight: 700;
}
.filterable-table-container .filter-container {
  position: relative;
}
.filterable-table-container .filter-container .filter-input,
.filterable-table-container .filter-container .page-size {
  width: 185px;
  display: inline;
}
.filterable-table-container .clear-filter {
  position: absolute;
  right: 10px;
  top: -4px;
}
.filterable-table-container span.sortable,
.filterable-table-container span.filterable {
  cursor: pointer;
}
.filterable-table-container span.filterable {
  border-bottom: none;
  box-shadow: inset 0 -2px 0 -1px var(--primary-cat-color);
  transition: box-shadow .3s ease-in-out;
  line-height: inherit;
}

.filterable-table-container span.filterable:hover {
  box-shadow: inset 0 -40px 0 -1px var(--primary-cat-color);
}
.filterable-table-container ul.pagination li a {
  user-select: none;
}
.filterable-table-container ul.pagination li:not(.disabled) a {
  cursor: pointer;
}
.filterable-table-container span.empty {
  font-style: italic;
  color: #999;
}
.filterable-table-container .filter-item {
  border: 1px solid #34BE82;
  border-radius: 1px;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
}
.filterable-table-container .filter-item .filter-item-title {
  background-color: #34BE82;
  border-radius: 1px;
  color: #fff;
}
.filterable-table-container .filter-item .filter-item-title .filter-item-remove {
  color: #fff;
  font-weight: 700;
  user-select: none;
  padding-right: 10px;
  cursor: pointer;
}
.filterable-table-container .filter-item .filter-item-title,
.filterable-table-container .filter-item .filter-item-value {
  padding: 5px 10px;
  display: inline-block;
}

.table-container .pagination li {
  margin-right: 10px;
}