#contact-sticky {
    position: fixed;
    right: 16px;
    bottom: 16px;
    cursor: pointer;
    width: 90px;
    z-index: 11;
}

#sidebar_content {
    position: fixed;
    top: 50px;
    right: -356px;
    width: 356px;
    bottom: 0;
    padding-top: 30px;
    background-color: #f2f2f2;
    transition: right 0.4s;
    z-index: 12;
}

.sidebar--visible #sidebar > #sidebar_content {
    right: 0;
}

#sidebar_overlay {
    position: fixed;
    background-color: var(--secondary-cat-color);
    opacity: 0.0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.4s ease;
    z-index: 12;
}

.sidebar--visible #sidebar > #sidebar_overlay {
    visibility: visible;
    opacity: 0.6;
}

#sidebar_control {
    position: absolute;
    margin-top: -2px;
    right: 15px;
}

#sidebar_control > i {
    font-size: 2em;
}

.overlay-close-button {
    color: var(--primary-cat-color);
    cursor: pointer;
    font-size: 22px;
}

#sidebar_content {
    font-size: 16px;
}

#sidebar_content h3 {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: univers;
    margin-bottom: 0.5em;
}

#sidebar_content .content {
    padding: 0 19px 34px;
}

#sidebar_content .call-pon-power {
    color: #606060;
    margin-top: 3em;
}

#sidebar_content .call-pon-power  h4 {
    font-size: 20px;
    font-family: univers;
    margin-bottom: 16px;
}

#sidebar_content .call-pon-power .fa {
    font-size: 20px;
}

#sidebar_content .contact-notice {
    margin-top: 40px;
    font-size: 16px;
    font-family: Oxygen;
    font-weight:normal;
    color: var(--secondary-cat-color);
}

.call-pon-power a {
    font-family: inherit;
    color: inherit;
}

.call-pon-power__method > a > span {
    margin-left: 1.25em;
}

.call-pon-power__method + .call-pon-power__method {
    margin-top: 1em;
}
