.notification {
    font-size: 16px;
	box-sizing: border-box;
	position: fixed;
	z-index: 999999;
    top: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.notification-container {
	background: #fff;
	transition: .3s ease;
	position: relative;
    display: flex;
	flex-direction: row;
    align-items: center;
	pointer-events: auto;
	overflow: hidden;
	padding: 15px 40px 15px 20px;
	border-radius: 3px;
	box-shadow: 0 0 10px #999;
	color: #000;
	border-left: solid 6px transparent;
}

.notification-container.success {
    border-left-color: #5cb85c;
}

.notification-container.success .notification-icon i {
    color: #5cb85c;
}

.notification-container.warning {
    border-left-color: #e6be0e;
}

.notification-container.warning .notification-icon i {
    color: #e6be0e;
}

.notification-container.danger {
    border-left-color: #cf0c36;
}

.notification-container.danger .notification-icon i {
    color: #cf0c36;
}

.notification-container.info {
    border-left-color: #127de0;
}

.notification-container.info .notification-icon i {
    color: #127de0;
}

.notification-title {
	font-weight: bold;
	margin: 0;
}

.notification-message {
	margin: 0;
}

.notification-icon {
    float: left;
    margin-right: 20px;
}

.notification-icon i {
	font-size: 20px;
}

.notification-container button {
	position: absolute;
	right: 15px;
	top: 10px;
	font-size: 16px;
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0
}

@keyframes toast-in-right {
	from {
	  transform: translateX(100%);

	}
	to {
	  transform: translateX(0);
	}
}