:root {
  --primary-cat-color: #FDBA12;
  --secondary-cat-color: #000;
}

.App {
  text-align: center;
}

header {
  background: var(--primary-cat-color);
}

.grid__row {
  background-color: var(--primary-cat-color);
}

ul {
  list-style-type: none;
}

