body {
  margin: 0;
  font-family: oxygen, oxygen-bold, univers, univers-bold, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'univers';
  src:  url('./media/univers-condensed-medium.woff2') format('woff2'),
        url('./media/univers-condensed-medium.woff') format('woff'),
        url('./media/univers-condensed-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'univers-bold';
  src:  url('./media/univers-condensed-bold.woff2') format('woff2'),
        url('./media/univers-condensed-bold.woff') format('woff'),
        url('./media/univers-condensed-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'oxygen';
  src:  url('./media/Oxygen-Regular.woff2') format('woff2'),
        url('./media/Oxygen-Regular.woff') format('woff'),
        url('./media/Oxygen-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'oxygen-bold';
  src:  url('./media/Oxygen-Bold.woff2') format('woff2'),
        url('./media/Oxygen-Bold.woff') format('woff'),
        url('./media/Oxygen-Bold.ttf') format('truetype');
  font-weight: bold;
}

#root > div {
    padding-top: 55px;
}

@media only screen and (max-width: 765px) {
  #root > div {
      padding-top: 51px;
  }
}

h1, h2 {
  font-family: univers-bold, univers;
}
h3, h4, h5, a, button {
  font-family: univers, univers-bold;
}