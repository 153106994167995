#faq-page h1 {
    padding: 65px 0 40px 0;
    font-size: 28px;
    text-transform: uppercase;
}

#faq-banner {
    position: relative;
    height: 452px;
    overflow: hidden;
}

#faq-banner img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

#faq-overlay {
    position: absolute;
    bottom: 0;
    height: 240px;
    width: 720px;
    background-color: var(--secondary-cat-color);
    color: white;
    margin-left: -50px;
    padding: 40px 50px;
}

#faq-overlay h1 {
    font-family: univers;
    color: var(--primary-cat-color);
    font-weight: bold;
    font-size: 24pt;
    margin: 0 0 15px 0;
    padding: 0;
}

#faq-overlay #faq-myponpower {
    background-color: var(--primary-cat-color);
    font-family: univers;
    font-weight: normal;
    font-size: 24px;
    color: var(--secondary-cat-color);
    padding: 5px 18px;
    position: absolute;
    top: -46px;
    left: 0;
}

#faq-overlay p {
    font-family: Oxygen;
    color: white;
    font-size: 13pt;
    font-weight: normal;
}

.faq-questions {
    background-color: #fff;
    margin: 0;
    padding: 0 50px;
}

.faq-question {
    padding: 30px 0;
    overflow: hidden;
    transition: max-height 0.5s;
    border-bottom: #f2f2f2 1px solid;

}

.faq-question.collapsed {
    max-height: 80px !important;
}

.faq-question .fa-chevron-down, .fa-chevron-up {
    color: var(--primary-cat-color);
}

.faq-question.collapsed .fa-chevron-up {
    display: none;
}

.faq-question:not(.collapsed) .fa-chevron-down {
    display: none;
}

.faq-question span {
    cursor: pointer;
    margin: 0;
}

.faq-question h4 {
    font-family: univers;
    font-size: 18px;
    font-weight: normal;
    width: calc(100% - 25px);
}

.faq-question p {
    font-family: Oxygen;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: normal;
    color: #606060;
}

.faq-question a {
    font-family: Oxygen;
    font-size: 16px;
    text-decoration: underline;
    font-weight: normal;
    color: #606060;
}

#faq-cant-find-answer {
    background-color: white;
    border-top: var(--primary-cat-color) 4px solid;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 76px;
    margin-bottom: 82px;
    padding: 52px;
}

#faq-cant-find-answer h1 {
    font-size: 28px;
    color: var(--secondary-cat-color);
    margin:0;
    padding: 0;
    font-family: univers;
    font-weight: normal;
}

#faq-cant-find-answer p {
    font-family: Oxygen;
    font-weight: normal;
    font-size: 16px;
    margin: 15px 0 15px 0;
}

#faq-cant-find-answer a {
    color: var(--secondary-cat-color);
}

@media only screen and (max-width: 765px) {
    #faq-overlay {
        margin-left: 0;
        width: calc(100% - 30px);
        padding: 30px 20px;
        height: auto;
        right: 15px;
        left: 15px;
    }

    #faq-overlay h1 {
        font-size: 20pt;
    }

    #faq-overlay p {
        font-size: 11pt;
    }

    .faq-questions {
        padding: 0 20px;
    }

    #faq-cant-find-answer {
        padding: 30px 20px;
    }
}