#admin_page {
    padding: 40px 0 20px !important;
}

form {
    text-align: left;
    padding: 15px 0;
    background: transparent;
    color: #000;
    width: 100%;
    position: relative;
}

.btn {
    margin: 0;
    margin-bottom: 10px;
}

.btn-yellow {
    background: var(--primary-cat-color);
}
.btn-yellow:hover,
.btn-yellow:focus {
    background: #d19a0f;
}

.btn-delete {
    color: #f00;
}

.btn-yellow i.fas,
a i.fas {
    margin-right: 7px;
}

table tbody tr td:first-of-type {
    font-weight: normal;
}

table tbody tr td input {
    width: 100%;
}

.action-row {
    margin-top: 20px;
}