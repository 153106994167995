.flash-message-container {
    background-color: var(--secondary-cat-color);
    margin-top: -0.2em;
    margin-bottom: 0.2em;
}

.flash-message-container .container {
    padding: 9px 0;
}

.flash-message {
    margin: 0;
    padding: 0;
    color: white;
    font-family: univers;
    font-weight: normal;
    font-size: 16px;
}
