form {
    text-align: center;
    color: #f2f2f2;
    background-color: var(--secondary-cat-color);
    padding-top: 25px;
}

label {
    margin-right: 40px;
}

.btn {
    margin: 100px 0px 25px 25px;
}

#login_page {
    margin-top: -0.2em;
    overflow: hidden;
}

#login_page img {
    object-fit: cover;
    object-position: top center;
    height: 58vh;
    width: 100%;
}

@media only screen and (max-width: 765px) {
    #login_page img {
        height: 36vh;
        object-fit: contain;
    }
}

.login_card {
    z-index: 1;
    color: white;
    font: 14pt univers;
    font-weight: lighter;

    transition: margin 300ms;
}

.login_card h1 {
    font-size: 24pt;
    font-family: univers;
    color: var(--primary-cat-color);
    line-height: 24pt;
    font-weight: normal;
    margin: 0;
}

.login_card p {
    margin: 30px 0px;
    font-size: 10pt;
}

.login_card button {
    border-radius: 0;
    border: 1px solid var(--secondary-cat-color);
    margin: 0 20px 0 0;
    text-transform: uppercase;
    font: 16pt univers;
    font-weight: bold;
    letter-spacing: 1px;
}

.login_card .sign-up:hover {
    border: 1px solid #f2f2f2;
    color: #f2f2f2;
}

.login_card .btn-custom:hover {
    color: #f2f2f2;
}

.login_card button > span {
    margin: 0 15px;
}

.login_card > div, .about > div {
    padding: 30px;
}

.login_card ul {
    margin: 0;
    padding: 0;
}

.login_card li {
    margin: 10px 0;
    line-height: 24pt;
    font-size: 10pt;
}

.login_card a {
    display: block;
    color: var(--secondary-cat-color);
}

.login_card a {
    margin-bottom: 5px;
    display: inline-block;
    font-family: univers;
}

.login_card .column {
    margin-bottom: 15px;
}

.login_card .column p a {
    margin-bottom: 5px;
}

.login-explanation {
    padding: 20px;
}

.login-form {
    background-color: var(--primary-cat-color);
    color: var(--secondary-cat-color);
}

.login-form h2 {
    font-weight: normal;
    color: var(--secondary-cat-color);
    margin-top: -0.8vw;
}

.login-form p {
    font-size: 11pt;
    font-family: oxygen;
    margin-top: 25px;
}

.card-icon {
    margin-right: 10px;
}

.card-icon .icon-background {
    color: var(--primary-cat-color);
}

.card-icon .icon-foreground {
    color: var(--secondary-cat-color);
}

.about {
    background: #f2f2f2;
}

.textTitle {
    margin: 0px 0px 30px 0px;
    font-weight: bold;
}

.text {
    font-size: 10pt;
    font-family: oxygen;
    font-weight: 500;
    margin-bottom: 30px;
}

.card-info {
    background-color: rgba(0, 0, 0);
}

.card-info h1 {
    font-weight: bold;
}

.card-info p,
.card-info li {
    font-family: oxygen;
}

.btn-custom {
    background-color: var(--secondary-cat-color);
    color: #f2f2f2;
}

.sign-up a {
    margin: 0px;
    text-decoration: none;
}

#help_me_now {
    position: relative;
    margin-top: 1vh;
    right: 30px;
}

.signup-link a {
    border: 1px var(--secondary-cat-color) solid;
    font-weight: bold;
    font-size: 16pt;
    color: var(--secondary-cat-color);
    padding: 6px 12px;
    width: auto;
    margin: 0;
    text-align: center;
}

.signup-link a:hover {
    border: 1px white solid;
    color: white;
    text-decoration: none;
}

.container-lg {
    max-width: 1140px;
    margin: 0 auto;
}

.featured-media {
    height: 200px;
    margin: 0 auto;
    background: var(--secondary-cat-color);
    background-image: url('../../media/login-header-640x430.jpg');
    background-size: cover;
    background-position: 50%;

    transition: height 300ms;
}

.featured-media > img {
    display: none;
}

@media screen and (min-width:768px)
{
    .card-info {
        background-color: rgba(0, 0, 0, 0.9);
    }

    .login-form h2 {
        margin-top: 0vw;
    }

    .login-form p {
        font-size: 4pt;

    }

    .login_card li {
        font-size: 12pt;
    }

    .login_card p {
        font-size: 12pt;
    }

    .text {
        font-size: 12pt;
    }

    .signup-link a {
        width: 132px;
    }
    .featured-media {
        height: 350px;
    }
}

@media screen and (min-width:992px) {
    .login_card > div, .about > div {
        padding: 60px;
    }
    .featured-media {
        height: 450px;
    }
    .login_card {
        margin-top: -150px;
    }
    #help_me_now {
        right: 0px;
    }
}

@media screen and (min-width:1200px) {
    .featured-media {
        height: 550px;
    }
    .login_card {
        margin-top: -200px;
    }
}

@media screen and (max-width:1199px) {
    .container-lg {
        max-width: 960px;
    }
}
